import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  HostBinding,
  Input,
  ViewChild,
  inject,
  output,
} from '@angular/core';
import { type ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NgStyle, NgTemplateOutlet } from '@angular/common';
import { AssetSrcDirective } from '@core/directives/asset-src.directive';
import { FocusableDirective } from '@core/directives/focusable.directive';
import { NgScrollbar } from 'ngx-scrollbar';

export interface SelectOption {
  value?: any;
  label?: string;
  key?: string;
  title?: string;
  disabled?: boolean;
  nonRemovable?: boolean;
  buttons?: SelectOptionButton[];

  [x: string]: any;
}

export interface SelectOptionButton {
  iconSrc: string;
  callback: () => any;
}

export interface SelectGroup {
  label: string;
  options: SelectOption[];

  [x: string]: any;
}

export interface SelectSpecialOption {
  label: string;
  callback: () => any;
  disabled?: boolean;
  title?: string;
}

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [
    FormsModule,
    AssetSrcDirective,
    FocusableDirective,
    NgStyle,
    NgScrollbar,
    NgTemplateOutlet,
    TranslateModule,
  ],
})
export class SelectComponent implements ControlValueAccessor {
  private readonly element = inject(ElementRef);
  private readonly translate = inject(TranslateService);
  private readonly changeDetectorRef = inject(ChangeDetectorRef);
  @Input() autocomplete = 'off';
  @Input() multiselect = false;
  @Input() placeholder: string;
  @Input() options: SelectOption[] = [];
  @Input() optionGroups: SelectGroup[] = [];
  @Input() returnFullOption = false;
  @Input() specialOptions: SelectSpecialOption[] = [];
  @Input() maxHeight = 300;
  @Input() lockValue = false;
  valueChanged = output<any>();
  @ViewChild('control') control: ElementRef;
  @ViewChild('displayedValueInput') displayedValueInput: ElementRef;
  @ViewChild('optionsArea') optionsArea: ElementRef;

  @HostBinding('class.disabled')
  public disabled = false;

  public isOpen = false;
  @HostBinding('style.height')
  public height: string;

  private searchValue = '';

  constructor() {
    this.clickEventListener = this.clickEventListener.bind(this);
    this.keydownEventListener = this.keydownEventListener.bind(this);
  }

  private _value: SelectOption[];

  public get value(): any {
    return this._value;
  }

  public set value(val: any) {
    if (!this.lockValue) {
      this._value = val;
    }
    this.onChange(val);
    this.onTouched();
    this.valueChanged.emit(val);
  }

  public get displayedValue(): string {
    if (!this.selectedOption) {
      return '';
    }

    if (!this.translate) {
      return this.selectedOption.label || this.selectedOption.title;
    }

    return this.translate.instant(this.selectedOption.label || this.selectedOption.title);
  }

  public set displayedValue(label: string) {
    const newOption = this.availableOptions.find((option) => option.label === label);
    this.value = newOption?.value || newOption?.key;

    if (!this.value) {
      this.displayedValueInput.nativeElement.value = '';
    }
  }

  public get selectedOption(): SelectOption {
    if (this.returnFullOption) {
      return this.value;
    } else {
      let option = this.options ? this.options.find((opt) => this.value === this.getOptionValue(opt)) : null;

      if (option) {
        return option;
      }

      this.optionGroups.forEach((group) => {
        option = group.options.find((opt) => this.value === this.getOptionValue(opt));
      });

      return option;
    }
  }

  public get availableOptions(): SelectOption[] {
    if (!this.multiselect) {
      return this.options;
    }

    const av = this.options.filter((opt) => !this.isOptionSelected(opt));
    return av;
  }

  public getOptionValue(option: SelectOption): any {
    if (option.value !== undefined) {
      return option.value;
    }

    if (option.key !== undefined) {
      return option.key;
    }

    return option;
  }

  public ngOnInit(): void {
    document.addEventListener('click', this.clickEventListener);
    document.addEventListener('keydown', this.keydownEventListener);
  }

  public ngOnDestroy(): void {
    document.removeEventListener('click', this.clickEventListener);
    document.removeEventListener('keydown', this.keydownEventListener);
  }

  public ngAfterContentInit(): void {
    this.recalculateHeight();
  }

  public isOptionSelected(opt: SelectOption): boolean {
    if (!this.value) {
      return false;
    }

    if (!this.multiselect) {
      return (this.returnFullOption ? this.getOptionValue(this.value) : this.value) === this.getOptionValue(opt);
    }

    return this.value.find(
      (selectedOpt) =>
        (this.returnFullOption ? this.getOptionValue(selectedOpt) : selectedOpt) === this.getOptionValue(opt),
    );
  }

  public selectSpecialOption(event: MouseEvent, option: SelectSpecialOption): void {
    event.stopPropagation();
    option.callback();
  }

  public selectOption(event: MouseEvent, option: SelectOption): void {
    if (this.disabled) {
      return;
    }

    event.stopPropagation();
    if (this.returnFullOption) {
      this.value = this.multiselect ? [...this.value, option] : option;
    } else {
      this.value = this.multiselect ? [...this.value, this.getOptionValue(option)] : this.getOptionValue(option);
    }
    if (!this.multiselect) {
      this.isOpen = false;
    }

    this.recalculateHeight();
    this.changeDetectorRef.detectChanges();
  }

  public unselectOption(event: MouseEvent, option: SelectOption): void {
    if (this.disabled || option.nonRemovable) {
      return;
    }

    event.stopPropagation();
    if (this.returnFullOption) {
      this.value = this.value.filter((opt) => this.getOptionValue(opt) !== this.getOptionValue(option));
    } else {
      this.value = this.value.filter((opt) => opt !== this.getOptionValue(option));
    }
    this.recalculateHeight();
    this.isOpen = true;
    this.changeDetectorRef.detectChanges();
  }

  public getOptionsDropdownHeight(): string {
    if (!this.isOpen) {
      return '0';
    }
    let optionsNumber = this.availableOptions?.length + this.specialOptions?.length;
    this.optionGroups.forEach((group) => (optionsNumber += group.options.length + 1));

    const optionHeight = this.multiselect ? 48 : this.displayedValueInput.nativeElement.clientHeight;
    if (optionsNumber > +this.maxHeight / optionHeight) {
      return `${this.maxHeight}px`;
    }

    return `${optionsNumber * optionHeight}px`;
  }

  public toggle(event: Event): void {
    if (this.disabled) {
      return;
    }

    this.isOpen = !this.isOpen;
    this.searchValue = '';
    event.stopPropagation();
  }

  public writeValue(value: SelectOption[]): void {
    if (value == null && this.multiselect) {
      this._value = [];
    } else {
      this._value = value;
    }
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public getLabelForValue(val: string): string {
    const option = this.options?.find((opt) => this.getOptionValue(opt) === val);
    return option?.title || option?.label;
  }

  public optionButtonClick(event: MouseEvent, button: SelectOptionButton): void {
    event.stopPropagation();
    button.callback();
  }

  private onChange = (val: SelectOption[]) => {};

  private onTouched = () => {};

  private clickEventListener(event: MouseEvent): void {
    if (this.disabled) {
      return;
    }

    this.isOpen = this.element.nativeElement.contains(event.target);
    this.searchValue = '';
  }

  private keydownEventListener(event: KeyboardEvent): void {
    if (this.isOpen) {
      const scrollArea = this.optionsArea.nativeElement.querySelector('.scrollable-area') as HTMLElement;
      const elements = scrollArea.querySelectorAll('.select_option');

      if (event.key === 'Escape') {
        this.searchValue = '';
        scrollArea.scrollTo(0, 0);
        (elements[0] as HTMLElement).focus();
        event.stopPropagation();
        return;
      }

      if (!event.ctrlKey && !event.metaKey && !event.altKey && event.code !== 'Backspace') {
        this.searchValue += event.key;
        const foundElement = [].find.call(elements, (opt: HTMLElement, id: number) => {
          const text = opt.innerText.toLowerCase();
          return text.includes(this.searchValue);
        });
        if (foundElement) {
          scrollArea.scrollTo(0, foundElement.offsetTop);
          foundElement.focus();
        }
      }
    }
  }

  private recalculateHeight(): void {
    setTimeout(() => {
      if (!this.control || !this.multiselect) {
        return;
      }
      this.height = this.control.nativeElement.clientHeight + 'px';
    }, 0);
  }
}
