import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  NgZone,
  type SimpleChanges,
  ViewChild,
  ViewEncapsulation,
  inject,
  output,
} from '@angular/core';
import {
  type TableBulkAction,
  type TableButton,
  type TableColumnConfig,
  TableConfig,
  TableFeatureType,
  type TableFilter,
  TableLayout,
} from '../../models/table';
import { HttpService } from '../../services/http.service';
import { of, Subject } from 'rxjs';
import { auditTime, catchError, finalize, map, takeWhile } from 'rxjs/operators';
import { ScrollableAreaComponent } from '../scrollable-area/scrollable-area.component';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DateService } from '@core/services/date.service';
import { differenceInDays } from 'date-fns';
import { generateCsv } from '@core/helpers/generate-csv';
import { downloadBlob } from '@core/helpers/download-blob';
import { NgStyle, NgTemplateOutlet } from '@angular/common';
import { ButtonLoadingDirective } from '@core/directives/button-disable.directive';
import { SelectComponent } from '@core/components/select/select.component';
import { FormsModule } from '@angular/forms';
import { AssetSrcDirective } from '@core/directives/asset-src.directive';

import { LoaderComponent } from '@core/components/loader/loader.component';
import { FocusableDirective } from '@core/directives/focusable.directive';
import { CheckboxComponent } from '@design/forms/checkbox/checkbox.component';

@UntilDestroy()
@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [TranslatePipe],
  standalone: true,
  imports: [
    ButtonLoadingDirective,
    TranslateModule,
    SelectComponent,
    FormsModule,
    AssetSrcDirective,
    ScrollableAreaComponent,
    NgTemplateOutlet,
    NgStyle,
    LoaderComponent,
    FocusableDirective,
    CheckboxComponent,
  ],
})
export class TableComponent {
  private readonly http = inject(HttpService);
  private readonly elementRef = inject(ElementRef);
  private readonly changeDetectorRef = inject(ChangeDetectorRef);
  private readonly ngZone = inject(NgZone);
  private readonly dateService = inject(DateService);
  private readonly translatePipe = inject(TranslatePipe);
  @Input() config: TableConfig;
  @Input() template: any;
  @Input() placeholderTemplate: any;
  @Input() data: any[] = [];
  dataChange = output<any>();
  @Input() selectedEntities: any[] = [];
  selectedEntitiesChange = output<any[]>();
  @Input() selectedEntity: any;
  selectedEntityChange = output<any>();
  @Input() maxHeight: number;
  @Input() shrinkHeight = false;
  @Input() growHeight = true;
  @Input() withExport = false;
  @Input() autoHeight = false;

  countChange = output<number>();

  @ViewChild('scrollArea') scrollArea: ScrollableAreaComponent;
  public entities: any[] = [];
  public areAllSelected = false;

  public hideContent = false;
  public isLoading = false;
  public isSelectingAll = false;
  public gridColumns = '';
  public selectedSort: { columnName: string; direction: string };
  public filterValues: Record<string, any> = {};
  public filtersChanged$ = new Subject<any>();
  private searchVal = '';
  private totalEntities = 0;
  private loadedEntities = 0;
  private numberOfLoadingRequests = 0;
  private lastCalledRequest: Promise<any> = null;

  private isAlive = true;

  private readonly defaultSearchParamOptions = { name: 'query' };

  public get TableLayout(): typeof TableLayout {
    return TableLayout;
  }

  public get layout(): TableLayout {
    return this.config.layout ? this.config.layout : TableLayout.Table;
  }

  public get searchValue(): string {
    return this.searchVal;
  }

  public set searchValue(val: string) {
    this.searchVal = val;
    if (this.config.manualSearchBy) {
      this.entities = this.data.filter(
        (ent) => this.getValueForManualSearch(ent)?.toLowerCase().indexOf(val?.toLowerCase()) !== -1,
      );
    } else {
      this.filtersChanged$.next(null);
    }
  }

  public ngOnInit(): void {
    this.entities = this.data;
    this.generateTableStyles();
    this.searchValue = this.config.searchValue;
    this.config.searchParamConfig = this.config.searchParamConfig || this.defaultSearchParamOptions;

    if (this.config.filters) {
      this.config.filters.forEach((filter) => {
        this.filterValues[filter.name] = filter.defaultValue ? filter.defaultValue : filter.options[0].value;
      });
    }

    this.selectedSort = {
      columnName: this.config.defaultSort ? this.config.defaultSort : this.getDefaultSortColumn(),
      direction: this.config.defaultSortDirection ? this.config.defaultSortDirection : 'asc',
    };

    this.loadData(true);

    this.runManualSort();

    this.filtersChanged$
      .pipe(
        takeWhile((_) => this.isAlive),
        auditTime(500),
      )
      .subscribe(() => {
        this.loadData(true);
      });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.config) {
      this.generateTableStyles();
      if (this.selectedSort) {
        this.loadedEntities = 0;
        this.loadData(true);
      }
    }

    if (changes.data) {
      this.entities = this.data;

      if (!this.config.loadUrl) {
        this.totalEntities = this.entities.length;
        this.countChange.emit(this.totalEntities);
      }

      this.runManualSort();
      this.updateHeight();
    }

    if (changes.selectedEntities) {
      this.areAllSelected = this.selectedEntities?.length !== 0 && this.selectedEntities?.length === this.totalEntities;
    }
  }

  public ngOnDestroy(): void {
    this.isAlive = false;
  }

  exportCsv() {
    const formattedData: unknown[] = this.generateDataToExportByType(this.config.tableExportType);

    const timezoneNow = this.dateService.representLocalDateInProfileTimezoneDate(new Date());
    const dateForFileName = this.translatePipe.transform('campaignsPage.exportFileName', {
      date: this.dateService.format(timezoneNow, {
        date: 'numeric',
      }),
    });

    const csvContent = generateCsv(formattedData);
    const csvBlob = new Blob([csvContent], { type: 'text/csv' });
    downloadBlob(csvBlob, `${dateForFileName}.csv`);
  }

  public onFilterChange(): void {
    this.filtersChanged$.next(null);
  }

  public getSortIcon(column: TableColumnConfig): string {
    if (!this.selectedSort) {
      return '';
    }

    if (this.selectedSort.columnName === column.name) {
      return this.selectedSort.direction === 'desc'
        ? 'assets/svg/table/sort-desc.svg'
        : 'assets/svg/table/sort-asc.svg';
    }

    return 'assets/svg/table/sort.svg';
  }

  public onColumnClick(column: TableColumnConfig, event: MouseEvent): void {
    event.stopPropagation();

    if (column.onClick) {
      column.onClick();
    }

    if (!column.sortable || this.isLoading) {
      return;
    }

    if (this.selectedSort.columnName === column.name) {
      this.selectedSort.direction = this.selectedSort.direction === 'desc' ? 'asc' : 'desc';
    } else {
      this.selectedSort = {
        columnName: column.name,
        direction: 'asc',
      };
    }

    if (column.manualSortValueFunc) {
      this.runManualSort();
    } else {
      this.filtersChanged$.next(null);
    }
  }

  public runManualSort(): void {
    if (!this.selectedSort) {
      return;
    }

    const newData = [...this.data];
    const sortedColumn = this.config.columns.find((column) => column.name === this.selectedSort.columnName);
    const sortFunc = sortedColumn?.manualSortValueFunc;
    if (!sortFunc) {
      return;
    }

    newData.sort((a, b) => {
      return (this.selectedSort.direction === 'asc' ? sortFunc(a) > sortFunc(b) : sortFunc(a) < sortFunc(b)) ? 1 : -1;
    });

    this.entities = newData;
  }

  public onTableScroll(): void {
    if (this.isLoading) {
      return;
    }

    if (this.totalEntities !== undefined && this.entities.length >= this.totalEntities) {
      return;
    }

    if (!this.config.manualSearchBy && this.data.length !== 0) {
      this.loadData();
    }
  }

  public loadData(filtersChanged?: boolean): void {
    if (!this.config.loadUrl) {
      return;
    }

    this.isLoading = true;

    if (filtersChanged) {
      this.loadedEntities = 0;
      this.entities = [];
    }
    const request = this.config.method === 'POST' ? this.post() : this.get();
    this.lastCalledRequest = request;
    request
      .then((res: { data: any[]; total: number }) => {
        if (this.lastCalledRequest !== null && request !== this.lastCalledRequest) {
          return;
        }
        this.totalEntities = res.total;
        this.countChange.emit(this.totalEntities);

        let data;
        if (res.data) {
          data = res.data;
        } else {
          data = res;
        }
        const newData = filtersChanged ? data : [...this.entities, ...data];
        this.loadedEntities = newData.length;
        this.entities = newData;
        this.changeDetectorRef.detectChanges();
        this.dataChange.emit(newData);
        this.isLoading = false;
        this.updateHeight().then(() => {
          // checking the case when scrollable area has enough height to fit more entities than loaded on the first load
          setTimeout(() => {
            this.ngZone.runOutsideAngular(() => {
              if (this.totalEntities !== undefined && this.entities.length < this.totalEntities) {
                const scrollArea = (this.elementRef.nativeElement as HTMLElement).querySelector('scrollable-area');
                const scrollAreaContent = (this.elementRef.nativeElement as HTMLElement).querySelector(
                  '.scrollable-area',
                );
                if (scrollArea?.clientHeight > scrollAreaContent?.clientHeight) {
                  this.loadData();
                }
              }
            });
          }, 0);
        });
      })
      .catch(() => {
        this.numberOfLoadingRequests--;
        if (this.numberOfLoadingRequests > 0) {
          return;
        }
        this.isLoading = false;
        this.updateHeight();
      });
  }

  public async updateHeight(): Promise<any> {
    if (!this.scrollArea) {
      await Promise.resolve();
      return;
    }
    return await this.scrollArea.updateHeight();
  }

  public scrollBottom(): void {
    const el = (this.elementRef.nativeElement as HTMLElement).querySelector('.scrollable-area');
    el.scrollTop = el.scrollHeight - el.clientHeight;
  }

  public scrollTop(): void {
    const el = (this.elementRef.nativeElement as HTMLElement).querySelector('.scrollable-area');
    el.scrollTop = 0;
  }

  public isButtonDisabled(buttonConfig: TableButton): boolean {
    if (!buttonConfig.isDisabledFunc) {
      return false;
    }

    return buttonConfig.isDisabledFunc();
  }

  public isEntitySelected(entity: any): boolean {
    return this.selectedEntities.findIndex((ent) => this.areEntitiesEqual(ent, entity)) !== -1;
  }

  public changeSelection(entity: any): void {
    if (this.isEntitySelected(entity)) {
      this.selectedEntitiesChange.emit(this.selectedEntities.filter((ent) => !this.areEntitiesEqual(ent, entity)));
    } else {
      this.selectedEntitiesChange.emit([...this.selectedEntities, entity]);
    }
  }

  public onSelectAllClick(): void {
    if (this.selectedEntities.length !== (this.totalEntities || this.entities.length)) {
      if (this.config.selectAllFunc) {
        this.isSelectingAll = true;

        let filtersStr = `?limit=${this.totalEntities}`;

        filtersStr = this.addSearchValueToUrl(filtersStr);
        filtersStr = this.addQueryFiltersToUrl(filtersStr);

        this.config
          .selectAllFunc(filtersStr)
          .pipe(
            untilDestroyed(this),
            map((res) => {
              this.selectedEntitiesChange.emit(res);
            }),
            catchError((err) => {
              console.log(err);
              return of(err);
            }),
            finalize(() => (this.isSelectingAll = false)),
          )
          .subscribe();
      } else {
        this.selectedEntitiesChange.emit([...this.entities]);
      }
    } else {
      this.selectedEntitiesChange.emit([]);
    }
  }

  public onBulkActionClick(action: TableBulkAction): void {
    action.callback();
  }

  public onEntityClick(entity: any): void {
    if (!this.config.singleSelect) {
      return;
    }

    if (this.selectedEntity === entity) {
      this.selectedEntityChange.emit(null);
      return;
    }

    this.selectedEntityChange.emit(entity);
  }

  private getDefaultSortColumn(): string {
    return this.config.columns.find((column) => column.sortable)?.name;
  }

  private getValueForManualSearch(entity: any): string {
    const propNames = this.config.manualSearchBy.split('.');
    if (propNames.length === 1) {
      return entity[propNames[0]];
    }
    let val = entity;
    propNames.forEach((name) => {
      val = val[name];
    });
    return val;
  }

  private buildUrl(): string {
    let url = this.config.loadUrl;
    const alreadyHasParams = url.includes('?');
    url += `${alreadyHasParams ? '&' : '?'}by=${this.selectedSort.columnName}`;
    url += `&order=${this.selectedSort.direction}`;
    url += `&offset=${this.loadedEntities}`;
    url += `&limit=${this.config.loadLimit || 0}`;

    url = this.addQueryFiltersToUrl(url);
    url = this.addSearchValueToUrl(url);

    return url;
  }

  private areEntitiesEqual(ent1: any, ent2: any): boolean {
    if (this.config.compareFunc) {
      return this.config.compareFunc(ent1, ent2);
    } else {
      return ent1.id === ent2.id;
    }
  }

  private generateTableStyles(): void {
    let columnsString = `${this.config.selectable ? 'minmax(40px, 40px) ' : ''}`;
    columnsString += this.config.columns
      .map((column) => {
        return `minmax(${column.minWidth ? column.minWidth : '100px'}, ${column.maxWidth ? column.maxWidth : '1fr'})`;
      })
      .reduce((prev, curr) => `${prev} ${curr}`);

    this.gridColumns = columnsString;
  }

  private generateDataToExportByType(type: TableFeatureType): unknown[] {
    const fields = this.config.columns.map((column) => this.translatePipe.transform(column.label));
    fields.pop();

    let formattedData = [];
    switch (type) {
      case TableFeatureType.Tasks:
        break;
      case TableFeatureType.Workflows:
        break;
      case TableFeatureType.Campaigns:
        formattedData = this.data.map((item) => {
          const assignedTo = this.translatePipe.transform('campaignsPage.numberOfCompanies', {
            number: item.audienceCompaniesCount,
          });
          return {
            [fields[0]]: item.name,
            [fields[1]]: item.audienceCompany ? item.audienceCompany.name : assignedTo,
            [fields[2]]: differenceInDays(new Date(), new Date(item.publishedAt)),
            [fields[3]]: item.status,
            [fields[4]]: item.completionRate,
          };
        });
        break;
    }

    return formattedData;
  }

  private async post(): Promise<any> {
    const url = this.buildUrl();
    let body = {};
    if (this.config.bodyParamsFunc) {
      body = this.config.bodyParamsFunc() || body;
    }

    if (this.config.searchParamConfig?.isBodyParam) {
      body[this.config.searchParamConfig.name] = this.searchVal;
    }

    return await this.http.post(url, body);
  }

  private async get(): Promise<any> {
    const url = this.buildUrl();
    return await this.http.get(url);
  }

  private getSearchParamName(): string {
    return this.config.searchParamConfig?.name || this.defaultSearchParamOptions.name;
  }

  private addQueryFiltersToUrl(url: string): string {
    const queryFilters = this.config.filters && this.config.filters.filter((x) => !x.useInBody);
    if (queryFilters) {
      url += this.getFiltersString(queryFilters);
    }

    return url;
  }

  private getFiltersString(queryFilters: TableFilter[]): string {
    let str = '';
    for (const filter of queryFilters) {
      if (this.filterValues[filter.name]) {
        str += filter.toStringFunc
          ? `&${filter.toStringFunc(this.filterValues[filter.name])}`
          : `&${filter.queryParam}=${this.filterValues[filter.name]}`;
      }
    }

    return str;
  }

  private addSearchValueToUrl(filtersStr: string) {
    if (!this.config.searchParamConfig?.isBodyParam && this.searchVal) {
      filtersStr += `&${this.getSearchParamName()}=${encodeURIComponent(this.searchVal)}`;
    }

    return filtersStr;
  }
}
